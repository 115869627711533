import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import ChooseLocale from "./Components/ChooseLocale";
import { fetchLocale } from "./api";
import { fr, de, enGB, ro } from "date-fns/locale";

var currentCulture = sessionStorage.getItem("culture") || "en";

if (currentCulture) {
  const key = `locale-${currentCulture}`;
  let localeText = sessionStorage.getItem(key);
  let promise: Promise<any>;
  if (!localeText) {
    promise = fetchLocale(currentCulture)
      .then((json) => {
        localeText = JSON.stringify(json);
        localeText && sessionStorage.setItem(key, localeText);
        return json;
      })
      .catch((ex) => {
        console.error(ex.message);
        throw ex;
      });
  } else promise = Promise.resolve(JSON.parse(localeText));
  window["localePromise"] = promise.then((locale) => {
    window["locale"] = locale;
    init();
    return locale;
  });
}

export const LString = (key: string, ...args: any[]): string => {
  const locale = window["locale"] as Record<string, string>;
  const keyLowercase = key.toLowerCase();
  let found;
  for (let search of [key, keyLowercase]) {
    found = locale[search];
    if (!found) {
      const parts = search.split(".");
      if (parts.length > 1) {
        for (let i in locale) {
          if (locale[i].endsWith(parts[1])) {
            found = locale[i];
            break;
          }
        }
      }
    }
    if (found) break;
  }
  if (args.length) {
    return found?.replace(/\{(\d)\}/g, (m, o, s) => {
      const index = parseInt(o);
      return args[index];
    });
  }
  if (!found) console.error(`Could not find localised string for : ${key}`);
  return found;
};

export const getLocale = () => {
  return sessionStorage.getItem("culture");
};

export const getFNSLocale = () => {
  switch (getLocale()) {
    case "en":
      return enGB;
    case "de":
      return de;
    case "fr":
      return fr;
    case "ro":
      return ro;
    default:
      return enGB;
  }
};

export const init = () => {
  const localesElement = document.getElementById("locales");
  localesElement && ReactDOM.render(<ChooseLocale culture={currentCulture} />, localesElement);
};

import React, { useEffect, useRef, useState } from "react";
import { Menu } from "@headlessui/react";
import { Locale } from "../models";
import { interpolatePiYG, rgb } from "d3";
import { fetchLocales } from "../api";

type Props = { culture: string };

const defaultLocale: Locale = {
  name: "English",
  abbr: "en",
  flag: "https://formworkspublic.blob.core.windows.net/icons/en.svg",
};

const ChooseLocale: React.FC<Props> = ({ culture }) => {
  const formRef = useRef<HTMLFormElement>();
  const [allowedLocales, setAllowedLocales] = useState<Locale[]>([defaultLocale]);
  const [current, setCurrent] = useState<Locale>(() => defaultLocale);

  useEffect(() => {
    const _init = async () => {
      const locales = await fetchLocales();
      let found = locales.find((l) => l.abbr === culture);
      found = found || locales.find((l) => l.abbr === "en");
      setAllowedLocales(locales);
      if (found) setCurrent(found);
    };

    _init();
  }, []);

  const onLocaleChange = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formRef.current["locale"].value = e.currentTarget.dataset["id"];
    formRef.current.submit();
  };

  return (
    <form method="POST" action={`${window.location.origin}/locale`} ref={formRef}>
      <input type="hidden" name="locale" id="locale"></input>
      <Menu as="div" style={{ position: "relative" }}>
        <Menu.Button style={{ padding: "0.2rem", border: "solid 1px #D1D5DB" }}>
          <a style={{ display: "flex", alignItems: "center" }}>
            <img style={{ width: 25 }} src={current.flag} />
            <span style={{ marginLeft: "1rem", textTransform: "uppercase", color: "white" }}>{current.abbr}</span>
          </a>
        </Menu.Button>
        <Menu.Items>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              marginTop: "1.5rem",
              textAlign: "left",
              padding: "0.5rem",
              backgroundColor: "white",
              borderRadius: "10%",
              border: "1px solid #E5E7EB",
              zIndex: 100,
            }}
          >
            {allowedLocales
              .filter((l) => l.abbr !== current?.abbr)
              .map((l) => (
                <Menu.Item key={l.abbr}>
                  {({ active }) => (
                    <a
                      key={l.abbr}
                      href="#"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0.3rem 0.2rem",
                        textDecoration: "none",
                        color: "black",
                        padding: "0.2rem",
                        backgroundColor: active ? "#E5E7EB" : "white",
                      }}
                      data-id={l.abbr}
                      onClick={l.abbr === current.abbr ? null : onLocaleChange}
                    >
                      <figure style={{ width: 25, margin: 0 }}>
                        <img style={{ width: "100%", maxWidth: "100%", objectFit: "contain" }} src={l.flag} />
                      </figure>
                      <span style={{ flexGrow: 1, marginLeft: "1rem", textTransform: "uppercase" }}>{l.abbr}</span>
                    </a>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Menu>
    </form>
  );
};

export default ChooseLocale;

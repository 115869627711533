import { Locale } from "./models";
const baseUrl = `${location.origin}/`;

export const fetchLocales = async (): Promise<Locale[]> => {
  const locales: Locale[] = [];
  const url = `${baseUrl}locales`;
  const resp = await fetch(url, { method: "GET" });
  const json = await resp.json();
  return json["languages"].map((l) => ({
    name: l["lang_name"],
    abbr: l["lang_iso"],
    flag: `https://formworkspublic.blob.core.windows.net/icons/${l["lang_iso"]}.svg`,
  }));
};

export const fetchLocale = async (culture: string): Promise<Record<string, string>> => {
  const cultures = [culture, "en"];
  for (let i = 0; i < cultures.length; i++) {
    const url = `${baseUrl}locale/${cultures[i]}`;
    const resp = await fetch(url, { method: "GET" });
    if (resp.status === 200 && resp.headers.get("Content-Type") === "application/json") return await resp.json();
    else if (i === 1) return null;
  }
};
